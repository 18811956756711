<template>
	<div class="notify_page">
		<div class="notify_page_content">
			<div class="page_title">消息中心</div>
			<div class="tabs">
				<a-badge :count="notReadList.length" style="margin-right: 68px">
					<div class="tabs_item" :class="{ active: !read_status }" @click="changeType(false)">未读</div>
				</a-badge>
				<a-badge count="0" style="margin-right: 740px">
					<div class="tabs_item" :class="{ active: read_status }" @click="changeType(true)">已读</div>
				</a-badge>
				<div class="clear_notRead" @click="clearNotRead">
					<img src="@/assets/img/清除未读.png" alt="" />
					清除未读
				</div>
			</div>
			<!-- 消息列表 -->
			<div class="table" v-show="list.length">
				<div class="table_header">
					<span class="table_header_txt">标题</span>
					<span class="table_header_txt">时间</span>
					<span class="table_header_txt">操作</span>
				</div>
				<div class="table_content">
					<div class="content_item" v-for="item in list" :key="item.id">
						<div class="item_txt item_title">{{ item.title }}</div>
						<div class="item_txt">{{ item.send_time }}</div>
						<div class="item_read item_txt" @click="$router.push('/manage/notify-detail?id=' + item.id)">查看详情</div>
					</div>
				</div>
			</div>
			<!-- 空内容 -->
			<div class="noMessage" v-show="!list.length">
				<img src="@/assets/img/noMessage.png" alt="" class="img" />
				<p class="title">暂无新消息</p>
			</div>
			<!-- 分页器 -->
			<div class="pagination" v-if="list.length">
				<div class="flex_holder"></div>
				<a-pagination v-model="currentPage" :total="total" :pageSize="pageSize * 1" @change="changePage" />
			</div>
		</div>
	</div>
</template>

<script>
import { messageList, readMessage } from "@/api/home.js";
export default {
	data() {
		return {
			checked: false,
			type: "notRead",
			currentPage: 1,
			pageSize: 10,
			list: [],
			readedList: [],
			notReadList: [],
			read_status: false,
			notReadNum: 0,
			readedNum: 0,
		};
	},
	mounted() {
		this.getList();
	},
	computed: {
		total() {
			return this.read_status ? this.readedList.length : this.notReadList.length;
		},
	},
	methods: {
		//获取总列表用于未读数量的展示
		getList() {
			messageList().then(res => {
				if (res.data.code == 200) {
					const data = res.data.data;
					this.readedList = data.list.filter(item => item.read_status);
					this.notReadList = data.list.filter(item => !item.read_status);
					this.currentPage = 1;
					this.$store.commit("updatenotReadNum", this.notReadList.length);
					if (this.read_status) {
						this.list = this.getCurrentList(this.readedList);
					} else {
						this.list = this.getCurrentList(this.notReadList);
					}
				}
			});
		},
		//获取当前分页列表用于消息列表的展示
		getCurrentList(list) {
			return list.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
		},
		clearNotRead() {
			this.$confirm({
				title: "确定将所有消息设置为已读状态吗?",
				onOk: () => {
					const promiseArr = this.notReadList.map(item => readMessage({ id: item.id }));
					Promise.all(promiseArr).then(res => {
						this.getList();
					});
				},
				onCancel() {},
			});
		},
		changeType(type) {
			this.read_status = type;
			this.currentPage = 1;
			this.list = this.getCurrentList(this.read_status ? this.readedList : this.notReadList);
			// this.list = type ? this.readedList : this.notReadList;
		},
		changePage(page, pageSize) {
			this.list = this.getCurrentList(this.read_status ? this.readedList : this.notReadList);
		},
	},
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.notify_page {
	width: 1200px;
	min-height: 830px;
	margin: 19px auto 0;
	padding: 40px 120px;
	background-color: #fff;
	.page_title {
		margin-bottom: 28px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2c354b;
		line-height: 33px;
	}
	.tabs {
		margin-top: 30px;
		border-bottom: 1px solid #dedfe2;
		.flex(flex-start);
		.tabs_item {
			width: 32px;
			height: 32px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #878fa7;
			line-height: 32px;
			text-align: center;
			cursor: pointer;
			&.active {
				font-size: 16px;
				font-weight: 500;
				color: #4c84ff;
				border-bottom: 2px solid #4c84ff;
			}
		}
		.clear_notRead {
			width: 76px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 20px;
			cursor: pointer;
			.flex();
		}
	}
	.table {
		margin-top: 15px;
		.table_header {
			padding: 0 88px;
			height: 56px;
			background-color: #ececec;
			.flex();
			.table_header_txt {
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2c354b;
				line-height: 22px;
			}
		}
		.content_item {
			height: 56px;
			padding: 0 64px 0 20px;
			background-color: #fff;
			border-bottom: 1px solid #dedfe2;
			.flex(flex-start);
			.item_txt {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #374567;
				line-height: 20px;
			}
			.item_read {
				margin-left: 278px;
				color: #4c84ff;
				cursor: pointer;
			}
			.item_title {
				width: 380px;
				margin-right: 10px;
				.ellipsis();
			}
			.item_title::before {
				content: "";
				display: inline-block;
				width: 8px;
				height: 8px;
				margin-right: 6px;
				background: #ff8e43;
				vertical-align: 1px;
				border-radius: 50%;
			}
		}
	}
	.noMessage {
		padding: 100px 340px;
		.title {
			margin-top: 14px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #374567;
			line-height: 20px;
			text-align: center;
		}
	}
	.pagination {
		margin-top: 50px;
		.flex();
		// /deep/ .ant-pagination-item-active {
		//   font-weight: 500;
		//   background: #374567;
		//   color: #fff;
		// }
	}
}
</style>
